import React, { Suspense, lazy } from "react";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga";
import { withCookies } from "react-cookie";
import "./style.css";
import * as actionTypes from "../src/store/actions/actionTypes";
import { Helmet } from "react-helmet";
import Loader from "./components/Common/Loader";
import { openAPI } from "./config";
import ErrorBoundary from "./components/Common/ErrorBoundary";

//import HomePage from '../src/components/HomePage/';
//Google Analytics initialization
ReactGA.initialize("UA-63531836-1");

//Blog
const BlogListing = lazy(() => import("./components/Blog/"));
const BlogDetail = lazy(() => import("./components/Blog/BlogDetail/index"));
const BlogPatternTwo = lazy(() =>
  import("./components/Blog/BlogPatternTwo/index")
);
const BlogPatternThree = lazy(() =>
  import("./components/Blog/BlogPatternThree/index")
);
const BlogPatternFour = lazy(() =>
  import("./components/Blog/BlogPatternFour/index")
);
const BlogPatternFive = lazy(() =>
  import("./components/Blog/BlogPatternFive/index")
);

//OpenPI
const OpenPI = lazy(() =>
  import("./components/Extranet/ProspectIntelligence/OpenPI")
);

//Vendor
const VendorProfile = lazy(() =>
  import("../src/components/Vendors/VendorProfile")
);
const VendorTestimonial = lazy(() =>
  import("../src/components/Vendors/VendorTestimonial")
);
const VendorCaseStudies = lazy(() =>
  import("./components/Vendors/VendorCaseStudy")
);
const VendorVideo = lazy(() => import("./components/Vendors/VendorVideo"));
const VendorDirectory = lazy(() =>
  import("./components/Vendors/VendorDirectoryList")
);
const VendorCategoryList = lazy(() =>
  import("./components/Vendors/VendorCategoryList")
);

const VendorCategoryListByLocation = lazy(() =>
  import("./components/Vendors/VendorCategoryList/ByLocationListing")
);

const VendorFAQ = lazy(() => import("./components/Vendors/VendorFAQ"));
const VendorReview = lazy(() => import("./components/Vendors/VendorReview"));
const VendorNotFound = lazy(() =>
  import("./components/Vendors/VendorNotFound")
);

//Extranet
const Login = lazy(() => import("./components/Extranet/Login"));
const VendorSignUp = lazy(() => import("./components/Extranet/VendorSignup/"));
const ExtranetProfile = lazy(() => import("./components/Extranet/Profile"));
const ExtranetProfileFAQ = lazy(() =>
  import("./components/Extranet/Profile/ProfileFAQ/")
);
const ExtranetProfilePreview = lazy(() =>
  import("./components/Extranet/Profile/VendorProfile")
);
const ExtranetContent = lazy(() => import("./components/Extranet/Content"));
const AddCompanyStep1 = lazy(() =>
  import("./components/Extranet/Content/PopUps/AddCompanyStep1")
);
const AddPersonStep2 = lazy(() =>
  import("./components/Extranet/Content/PopUps/AddPersonStep2")
);
const AddContentCaseStudyStep3 = lazy(() =>
  import("./components/Extranet/Content/PopUps/AddContentCaseStudyStep3")
);
const AddContentTestimonialStep3 = lazy(() =>
  import("./components/Extranet/Content/PopUps/AddContentTestimonialStep3")
);
const AddContentVideoStep3 = lazy(() =>
  import("./components/Extranet/Content/PopUps/AddContentVideoStep3")
);
const Settings = lazy(() => import("./components/Extranet/Settings/"));
const AwardAndReports = lazy(() =>
  import("./components/Extranet/AwardAndReports/")
);
const ProspectIntelligence = lazy(() =>
  import("./components/Extranet/ProspectIntelligence/index")
);
const Dashboard = lazy(() => import("./components/Extranet/Dashboard/"));
const Stats = lazy(() => import("./components/Extranet/Stats/"));
const FakeALogin = lazy(() =>
  import("./components/Extranet/Common/FakeALogin")
);

const OneTimeLogin = lazy(() =>
  import("./components/Extranet/Common/OneTimeLogin")
);

//import CSRDownloadForm from './components/Vendors/VendorProfile/Common/CSRDownloadForm';
const CSRDownloadForm = lazy(() =>
  import("./components/Vendors/VendorProfile/Common/CSRDownloadForm")
);

const HomePage = lazy(() => import("../src/components/HomePage/"));
const ContactUs = lazy(() => import("./components/ContactUs"));
const CSR = lazy(() => import("./components/CSR/"));
const SoftwareCategories = lazy(() => import("./components/Softwares/"));
const ServiceCategories = lazy(() => import("./components/Services/"));
const TermsAndCondition = lazy(() => import("./components/TermsAndCondition"));
const Privacy = lazy(() => import("./components/Privacy"));
const PageNotFound = lazy(() => import("./components/Common/PageNotFound"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const TeamAll = lazy(() => import("./components/AboutUs/TeamAll"));
const TeamMember = lazy(() => import("./components/AboutUs/TeamMember"));
const TestimonialByVendor = lazy(() =>
  import("./components/AboutUs/TestimonailByVendor")
);
const PressRelease = lazy(() => import("./components/AboutUs/PressRelease/"));
const PressReleaseDetail = lazy(() =>
  import("./components/AboutUs/PressRelease/PressReleaseDetails")
);
const FreeTrial = lazy(() => import("./components/FreeTrial/"));
const FreeSoftwareAdvice = lazy(() =>
  import("./components/FreeSoftwareAdvice")
);

const URL = process.env.REACT_APP_API_URL;

class App extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      currentAppLoc: "",
    };
    this.getAllCommonData = this.getAllCommonData.bind(this);
    this.getAllHome = this.getAllHome.bind(this);
    this.checkIsEnterpriseLoggedIn = this.checkIsEnterpriseLoggedIn.bind(this);
    //Home Page
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillMount() {
    this._isMounted = true;
    this.getAllCommonData();
  }

  componentDidCatch(error, errorInfo) {
    console.log("AppJS : error :: ", error);
    console.log("AppJS : errorInfo :: ", errorInfo);
  }

  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
   * Chatbot
   */

  /**
   * Web Site Common data
   */

  //intializing broadcastchannel

  getAllCommonData = () => {
    const { cookies } = this.props;
    openAPI
      .get(`${URL}/common`, {
        withCredentials: true,
      })
      .then(response => {
        if (response.data.status === 200) {
          this.props.saveCommonRecentResearch(response.data.recent_research);
          this.props.saveCommonServicesCategory(
            response.data.service_categories
          );
          this.props.saveCommonSWCategory(response.data.software_categories);
          this.props.saveCommonTtlCustCount(response.data.customer_count);
          this.props.saveCommonMiscCount(response.data.other_count);
          this.props.saveCommonSEOSchema(response.data.seo_schema);
          //calling subsequent request
          cookies.set(
            "fc_react_csrfmiddlewaretoken",
            response.headers["x-csrftoken"],
            {
              path: "/",
            }
          );
          this.getAllHome();
          axios.all([this.checkIsEnterpriseLoggedIn()]).then(
            axios.spread(isEnterpriseLoggedIn => {
              if (isEnterpriseLoggedIn.data.enterprise === true) {
                this.props.setEnterpriseLogin();
              }
            })
          );
        }
      })
      .catch(err => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 404
        ) {
          this.props.history.push("/pagenotfound");
        }
      });
  };

  /**
   * Get homepage data
   */
  getAllHome = () => {
    openAPI
      .get(`${URL}/home`, {
        withCredentials: true,
      })
      .then(response => {
        if (response.status === 200) {
          this.props.saveHomeFeaturedVideos([...response.data.featured_video]);
          this.props.saveHomeFeaturedTestimonial({
            featured_testimonial: response.data.featured_testimonial,
          });
          this.props.saveHomeFeaturedCaseStudy({
            featured_casestudy: response.data.featured_casestudy,
          });
          this.props.saveHomeTrustedBy([...response.data.fortune_1000]);
          this.props.saveHomePopularSoftware([
            ...response.data.popular_software_categories,
          ]);
          this.props.saveHomePopularServices([
            ...response.data.popular_service_categories,
          ]);
          this.props.saveHomeDiscoverCompanies([
            ...response.data.discover_companies,
          ]);
          this.props.saveHomeText({
            meta_description: response.data.meta_description,
            meta_title: response.data.meta_title,
            meta_keywords: response.data.meta_keywords,
          });
          this.props.saveCompanyFeaturedRefrence([
            ...response.data.featured_references,
          ]);
        }
      })
      .catch(err => {
        this.props.history.push("/pagenotfound");
      });
  };

  checkIsEnterpriseLoggedIn = () => {
    return openAPI.get(`${URL}/enterprise/check`, {
      withCredentials: true,
    });
  };

  render() {
    const routes = (
      <Suspense fallback={<Loader />}>
        <Switch>
          <Redirect
            from="/:url*(/+)"
            to={`${this.props.location.pathname.slice(0, -1)}${
              this.props.location.search
            }`}
          />
          <Route path="/" exact render={() => <HomePage pageType="home" />} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/terms" exact component={TermsAndCondition} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/customers-success-reports" exact component={CSR} />
          <Route path="/customers-success-reports/all" exact component={CSR} />
          <Route path="/software" exact component={SoftwareCategories} />
          <Route path="/services" exact component={ServiceCategories} />
          <Route path="/team" exact component={TeamAll} />
          <Route path="/team/:member" component={TeamMember} />
          <Route path="/get-free-advice" component={FreeSoftwareAdvice} />
          <Route
            path="/customer-testimonials"
            component={TestimonialByVendor}
          />
          <Route path="/press-releases" exact component={PressRelease} />
          <Route path="/press-releases/all" exact component={PressRelease} />
          <Route
            path="/press-releases/:name"
            exact
            component={PressReleaseDetail}
          />

          <Route
            path="/customer/:id/noresults"
            exact
            component={VendorNotFound}
          />
          <Route
            path="/vendor/:id/testimonials"
            exact
            component={VendorTestimonial}
          />
          <Route
            path="/vendor/:id/testimonials/all"
            exact
            component={VendorTestimonial}
          />
          <Route
            path="/vendor/:id/case-studies"
            exact
            component={VendorCaseStudies}
          />
          <Route
            path="/vendor/:id/case-studies/all"
            exact
            component={VendorCaseStudies}
          />

          <Route
            path="/vendor/:id/customer-videos"
            exact
            component={VendorVideo}
          />
          <Route
            path="/vendor/:id/videos"
            exact
            render={props => (
              <Redirect
                to={`/vendor/${props.match.params.id}/customer-videos`}
              />
            )}
          />
          <Route
            path="/vendor/:id/videos/all"
            exact
            render={props => (
              <Redirect
                to={`/vendor/${props.match.params.id}/customer-videos`}
              />
            )}
          />
          <Route
            path="/vendor/:id/customer-videos/all"
            exact
            component={VendorVideo}
          />

          <Route path="/vendor/:id/faq" exact component={VendorFAQ} />
          <Route
            path="/vendor/:id/customer-videos/popup"
            exact
            component={VendorVideo}
          />

          <Route path="/vendor/:id" exact component={VendorProfile} />
          <Route path="/vendor/:id/reviews" exact component={VendorReview} />
          <Route
            path="/vendor/:id/customers"
            exact
            render={props => (
              <Redirect to={`/vendor/${props.match.params.id}`} />
            )}
          />

          <Route
            path="/vendor/:id/customers/:misc"
            exact
            render={props => (
              <Redirect to={`/vendor/${props.match.params.id}/customers`} />
            )}
          />

          <Route path="/vendors/:alphabet" exact component={VendorDirectory} />
          <Route path="/vendors" component={VendorDirectory} />
          <Route
            path="/software/:domain"
            exact
            component={VendorCategoryList}
          />
          <Route
            path="/software/:domain/all"
            exact
            component={VendorCategoryList}
          />
          {/* <Route
            path="/software/:domain/:location"
            exact
            component={VendorCategoryListByLocation}
          />
          <Route
            path="/software/:domain/:location/all"
            exact
            component={VendorCategoryListByLocation}
          /> */}
          <Route
            path="/services/:domain"
            exact
            component={VendorCategoryList}
          />
          <Route
            path="/services/:domain/all"
            exact
            component={VendorCategoryList}
          />
          {/* <Route
            path="/services/:domain/:location"
            exact
            component={VendorCategoryListByLocation}
          />
          <Route
            path="/services/:domain/:location/all"
            exact
            component={VendorCategoryListByLocation}
          /> */}

          <Route path="/blog" exact component={BlogListing} />
          <Route path="/blog/all" exact component={BlogListing} />
          <Route path="/blog/:title" exact component={BlogDetail} />
          <Route path="/blog/pattern/2" exact component={BlogPatternTwo} />
          <Route path="/blog/pattern/3" exact component={BlogPatternThree} />
          <Route path="/blog/pattern/4" exact component={BlogPatternFour} />
          <Route path="/blog/pattern/5" exact component={BlogPatternFive} />
          <Route path="/openpi/:company/:lpcid" component={OpenPI} />
          <Route
            path="/vendor/:vendor_slug/customer-success-report/:report_slug"
            exact
            component={CSRDownloadForm}
          />

          <Route path="/vendor-solutions" component={VendorSignUp} />

          <Route path="/free-trial-signup" component={FreeTrial} />
          <Route path="/openpi/:company/:lpcid" component={OpenPI} />

          <Route
            path="/extranet/portal/superuser_login/:company"
            exact
            component={FakeALogin}
          />
          <Route path="/extranet/otl/:uuid" exact component={OneTimeLogin} />
          <Route
            path="/extranet/login"
            exact
            render={() => <Login cookies={this.props.cookies} />}
          />
          <Route
            path="/extranet/portal/:company/company-profile"
            exact
            render={() => <ExtranetProfile cookies={this.props.cookies} />}
          />
          <Route
            path="/extranet/portal/:company/company-profile/faq"
            exact
            component={ExtranetProfileFAQ}
          />
          <Route
            path="/extranet/portal/:company/company-profile/preview"
            exact
            render={() => <ExtranetProfilePreview />}
          />
          <Route
            path="/extranet/portal/:company/content"
            exact
            component={ExtranetContent}
          />
          <Route
            path="/extranet/portal/:company/addcontent/addcompany"
            exact
            component={AddCompanyStep1}
          />
          <Route
            path="/extranet/portal/:company/addcontent/addperson"
            exact
            component={AddPersonStep2}
          />
          <Route
            path="/extranet/portal/:company/addcontent/casestudy"
            exact
            component={AddContentCaseStudyStep3}
          />
          <Route
            path="/extranet/portal/:company/addcontent/testimonial"
            exact
            component={AddContentTestimonialStep3}
          />
          <Route
            path="/extranet/portal/:company/addcontent/video"
            exact
            component={AddContentVideoStep3}
          />
          <Route
            path="/extranet/portal/:company/setting/yourprofile"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/setting/vendorsettings"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/setting/usermanagement"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/setting/paymentandbilling"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/setting/yourplan"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/setting/paymentmethod"
            exact
            component={Settings}
          />
          <Route
            path="/extranet/portal/:company/customer-success-report/listing"
            exact
            component={AwardAndReports}
          />
          <Route
            path="/extranet/portal/:company/customer-success-report/asset"
            exact
            component={AwardAndReports}
          />
          <Route
            path="/extranet/portal/:company/customer-success-report/:detail"
            exact
            component={AwardAndReports}
          />
          <Route
            path="/extranet/portal/:company/prospectintelligence/new"
            exact
            component={ProspectIntelligence}
          />
          <Route
            path="/extranet/portal/:company/prospectintelligence/purchased"
            exact
            component={ProspectIntelligence}
          />
          <Route
            path="/extranet/portal/:company/prospectintelligence/all"
            exact
            component={ProspectIntelligence}
          />
          <Route
            path="/extranet/portal/:company/dashboard"
            exact
            component={Dashboard}
          />
          <Route
            exact
            path="/extranet/portal/:company/stats"
            component={Stats}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    );
    return (
      <React.Fragment>
        <ErrorBoundary>
          <Helmet>
            {navigator.userAgent.toLowerCase().indexOf("prerender") === -1 &&
              !window.location.pathname.includes("/extranet/portal/") && (
                <script
                  id="Cookiebot"
                  src="https://consent.cookiebot.com/uc.js"
                  data-cbid="56eeb697-67d7-4784-b1d9-d6d43ae4fd11"
                  data-blockingmode="auto"
                  type="text/javascript"
                ></script>
              )}
          </Helmet>

          {routes}
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ctr: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveWebsiteCommonData: data =>
      dispatch({ type: actionTypes.SAVE_WEBSITE_COMMON_DATA, payload: data }),
    saveHomePageData: data =>
      dispatch({ type: actionTypes.SAVE_HOMEPAGE_DATA, payload: data }),
    saveContactUsDropdown: data =>
      dispatch({ type: actionTypes.SET_COMPANY_DATA_CONTACTUS, payload: data }),
    saveCompanyTestimonialData: data =>
      dispatch({ type: actionTypes.SET_COMPANY_TESTIMONIAL, payload: data }),
    saveCompanyFeaturedRefrence: data =>
      dispatch({
        type: actionTypes.SAVE_HOMEPAGE_FEATURED_REFERENCE,
        payload: data,
      }),
    saveCompanyPressReleasesData: data =>
      dispatch({
        type: actionTypes.SET_COMPANY_INIT_PRESS_RELEASES,
        payload: data,
      }),
    saveVendorSolutionData: data =>
      dispatch({
        type: actionTypes.SET_VENDOR_SOLUTION_DATA,
        payload: data,
      }),
    saveCommonSWCategory: data =>
      dispatch({ type: actionTypes.SET_SW_CAT_DATA, payload: data }),
    saveCommonServicesCategory: data =>
      dispatch({ type: actionTypes.SET_SW_SERV_DATA, payload: data }),
    saveCommonRecentResearch: data =>
      dispatch({ type: actionTypes.SET_SW_RESEARCH_DATA, payload: data }),
    saveCommonTtlCustCount: data =>
      dispatch({ type: actionTypes.SET_TOTAL_CUSTOMER_DATA, payload: data }),
    saveCommonMiscCount: data =>
      dispatch({ type: actionTypes.SET_MISC_COUNT_DATA, payload: data }),
    saveCommonSEOSchema: data =>
      dispatch({ type: actionTypes.SET_SEO_DATA, payload: data }),
    saveCommonTracking: data =>
      dispatch({ type: actionTypes.SET_TRACKING_STATUS, payload: data }),

    saveHomeFeaturedVideos: data =>
      dispatch({ type: actionTypes.SET_HOME_F_VIDEOS, payload: data }),
    saveHomeFeaturedTestimonial: data =>
      dispatch({ type: actionTypes.SET_HOME_F_TESTIMONIAL, payload: data }),
    saveHomeFeaturedCaseStudy: data =>
      dispatch({ type: actionTypes.SET_HOME_F_CASESTUDY, payload: data }),
    saveHomeTrustedBy: data =>
      dispatch({ type: actionTypes.SET_HOME_TRUSTEDBY, payload: data }),
    saveHomePopularSoftware: data =>
      dispatch({ type: actionTypes.SET_HOME_POP_SW, payload: data }),
    saveHomePopularServices: data =>
      dispatch({ type: actionTypes.SET_HOME_POP_SERV, payload: data }),
    saveHomeDiscoverCompanies: data =>
      dispatch({ type: actionTypes.SET_HOME_DISCOVER_COMP, payload: data }),
    saveHomeText: data =>
      dispatch({ type: actionTypes.SET_HOME_TEXT, payload: data }),
    setEnterpriseLogin: () => dispatch({ type: actionTypes.ENTRERPRISE_LOGIN }),
  };
};

const appWithCookies = withCookies(withRouter(App));

export default connect(mapStateToProps, mapDispatchToProps)(appWithCookies);
